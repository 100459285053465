import {createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import Homepage from './Presumedigitech/Homepage';
import About from './Presumedigitech/About';
import Services from './Presumedigitech/Services';
import Digital from './Presumedigitech/Digital';
import Search from './Presumedigitech/Search';
import Social from './Presumedigitech/Social';
import Pay from './Presumedigitech/Pay';
import Website from './Presumedigitech/Website';
import Mobile from './Presumedigitech/Mobile';
import Testing from './Presumedigitech/Testing';
import Ai from './Presumedigitech/Ai';
import Cyber from './Presumedigitech/Cyber';
import Faq from './Presumedigitech/Faq'; 
import Terms from './Presumedigitech/Terms';
import Privacy from './Presumedigitech/Privacy';
import Refund from './Presumedigitech/Refund';
import Contact from './Presumedigitech/Contact';
import Portfolio from './Presumedigitech/Portfolio';
 
function App() {

  const router = createBrowserRouter([
    {path: '/', element: <Homepage />},
    {path: '/about', element: <About />},
    {path: '/services', element: <Services />},
    {path: '/digital', element: <Digital />},
    {path: '/social', element: <Social />},
    {path: '/pay', element: <Pay />},
    {path: '/website', element: <Website />},
    {path: '/mobile', element: <Mobile />},
    {path: '/testing', element: <Testing />},
    {path: '/aiml', element: <Ai />},
    {path: '/cyber', element: <Cyber />},
    {path: '/search', element: <Search />},
    {path: '/faq', element: <Faq />},
    {path: '/privacy', element: <Privacy />},
    {path: '/terms', element: <Terms />},
    {path: '/refund', element: <Refund />},
    {path: '/contact', element: <Contact />},
    {path: '/portfolio', element: <Portfolio />},
  ])
  return (
<>
    <RouterProvider router={router} />
</>
 );
}

export default App;
