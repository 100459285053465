import "./homepage.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
const Refund = () => {
  return (
    <>
      <Header />
      <div className="terms__container">
        <br />
        <br />
        <h1>Refund Policy</h1>

        <br />
        <br />
        <h4>
          Refunds will be provided only under the following circumstances:
        </h4>
        <br></br>
        <p>
          ☛ The purchased service is not delivered or does not function as
          intended for more than 2 consecutive days.
          <br />
          <br />
          <br />
          <h4>No refunds will be provided if:</h4>

          
          <br /> ☛ The client does not utilize the purchased service.
          <br />
          ☛ Any account or service access is suspended due to non-compliance
          with these Terms of Use.
          <br /><br/>
<br/>
          <h4>Refund will be provided if</h4><br/>
          ☛ Item doesn't work the way it should.
          <br />
          ☛ Item support is promised but not provided.
          <br /><br/>
          All refund requests must be directed to presumedigitech@gmail.com, and
          we reserve the right to approve or deny refunds at our discretion.
        </p>
        <br />
        <br />
      </div>

      <Footer />
    </>
  );
};

export default Refund;
