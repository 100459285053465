import React, { useState } from "react";
import "../Presumedigitech/homepage.css";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";

const Header = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [showAboutDropdown, setShowAboutDropdown] = useState(false);
  const [showServicesDropdown, setShowServicesDropdown] = useState(false);

  // Toggle the navbar for small screens
  const toggleNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  // Toggle the "About" dropdown
  const toggleAboutDropdown = () => {
    setShowAboutDropdown(!showAboutDropdown);
  };

  // Toggle the "Services" dropdown
  const toggleServicesDropdown = () => {
    setShowServicesDropdown(!showServicesDropdown);
  };

  return (
    <>
      <nav className="pre_header">
        <ul>
          <li>
            <a
              href="https://www.facebook.com/profile.php?id=61559499132143" 
              aria-label="Know more in facebook"
            >
              <FaFacebookF />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/presumedigitech/"
              aria-label="Know more in Instagram"
            >
              <FaSquareInstagram />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/presumedigitech/posts/?feedView=all"
              aria-label="Know more in Linkdin"
            >
              <FaLinkedinIn />
            </a>
          </li>
        </ul>
      </nav>

      <header className="header">
        <div id="logo">
          <Link to="/">
            <img
              src="/images/Presume Digi Tech Logo png.png"
              sizes="100x150"
              width="100"
              height="150"
              alt="Logo"
            ></img>
          </Link>
        </div>
        <div className="toggle-menu" onClick={toggleNavbar}>
          <span>☰</span>
        </div>
        <nav className={`navbar ${showNavbar ? "show" : ""}`}>
          <ul className="nav-links">
            <li>
              <Link to="/">
                <b>Home</b>
              </Link>
            </li>

            {/* About Dropdown */}
            <li className="dropdown">
              <a href="#" onClick={toggleAboutDropdown}>
                About <span className="arrow"></span>
              </a>
              <ul
                className={`dropdown-menu ${showAboutDropdown ? "show" : ""}`}
              >
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                {/* <li>
                <a href="#">Our Team</a>
              </li> */}
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li>
                  <Link to="/privacy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/refund">Refund Policy</Link>
                </li>
                {/* <li>
                <a href="#">Testimonials</a>
              </li> */}
              </ul>
            </li>

            {/* Services Dropdown */}
            <li className="dropdown">
              <a href="#" onClick={toggleServicesDropdown}>
                Services <span className="arrow"></span>
              </a>
              <ul
                className={`dropdown-menu ${
                  showServicesDropdown ? "show" : ""
                }`}
              >
                <li>
                  <Link to="/services">Our Services</Link>
                </li>
                <li>
                  <Link to="/digital">Digital Marketing</Link>
                </li>
                <li>
                  <Link to="/search">Search Engine Optimization (SEO)</Link>
                </li>
                <li>
                  <Link to="/social">Social Media Marketing (SMM)</Link>
                </li>
                <li>
                  <Link to="/pay">Pay-Per-Click (PPC) Advertising</Link>
                </li>
                <li>
                  <Link to="/website">Website Development</Link>
                </li>
                <li>
                  <Link to="/mobile">Mobile App Development</Link>
                </li>
                <li>
                  <Link to="/testing">Testing</Link>
                </li>
                <li>
                  <Link to="/aiml">AI/ML</Link>
                </li>
                <li>
                  <Link to="/cyber">Cyber Security Solutions</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/portfolio">Portfolio </Link>
            </li>
            <li>
              <Link to="/contact">
                <b>Contact&nbsp;us</b>
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Header;
