import React from 'react'
import "../Presumedigitech/homepage.css";
import { Link } from "react-router-dom";
const copyright = {
  color: "#17726D",
};

const Footer = () => {
  return (
    <div>
      {" "}
      <div className="footer">
        <div id="logo2">
          <div className="footer-logo">
            <Link to="/">
              <img src="/images/Presume Digi Tech Logo png.png" alt="Logo" />
            </Link>
          </div>
        </div>

        <div className="footer-column">
          <h2>Explore Us</h2>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/portfolio">Portfolio</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/Terms">Terms & Conditions</Link>
            </li>
            <li>
              <Link to="/refund">Refund Policy</Link>
            </li>
          </ul>
        </div>

        <div className="footer-column">
          <h3>Contact Us</h3>
          <ul>
            <li>
              <a href="#">
                410, 4th Floor, Apollo Premier, Vijay Nagar, Indore, Madhya
                Pradesh
              </a>
            </li>
            <div id="no">
              <p>
                <a href="tel:8959575773">+91 8959575773</a>
              </p>
            </div>
            <div id="email">
              <p>
                <a href="mailto:info@presumedigitech.com">
                  info@presumedigitech.com
                </a>
              </p>
            </div>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          © All rights reserved. Presume DigiTech<br></br>{" "}
          <b style={copyright} className="poweredBy">
            {" "}
            <a href="https://www.presumeoverseas.com/">
              Powered By Presume Overseas
            </a>
          </b>
        </p>
      </div>
    </div>
  );
}

export default Footer;