import React, { useState } from 'react';
import './homepage.css';
import {Link } from 'react-router-dom';
import Footer from '../component/Footer';
import Header from '../component/Header';

function Privacy() {

  return (
    
    <div>
       {/* Header Section */}
       <Header />

      <div id="digitalimage">

        <h1>
          Privacy and Policy
        </h1>
      </div>

<h1 id='privacy1'></h1>
          <p id='privacy2'>PRESUME DIGITECH is committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website (www.presumedigitech.com), use our services, or engage with our content.</p>

<h2 id='privacy3'>1. Information We Collect</h2>
<p id='privacy4'>
We may collect and process the following data:
</p>
<p id='privacy5'>
    <ul>
                  <li>Name, email address, phone number, company name, job title, and other contact details.</li>
                  <li>Information on how you interact with our website, including your IP address, browser type, pages visited, and duration of your visit.</li>
                  <li>Information such as location, preferences, and interests, which may be relevant for customer surveys and offers.</li>
                  <li>Cookies and similar technologies to enhance your experience, track user activity, and gather analytics.</li>
    </ul>
</p>
<h2 id='privacy3'>2. How We Use Your Information
</h2>
<p id='privacy4'>
We use the information we collect for the following purposes:
</p>
<p id='privacy5'>
    <ul>
                  <li>Deliver the services you request, enhance your experience, and personalize content.</li>
                  <li>Send promotional emails, updates, and offers, with your consent where required by law.</li>
                  <li>Contact you for surveys to improve our products and services.</li>
                  <li>Tailor our website to your preferences and interests using analytics data.</li>
                  <li>Ensure adherence to applicable laws, regulations, and legal processes.</li>
    </ul>
</p>
<h2 id='privacy3'>3. Sharing Your Information</h2>
<p id='privacy4'>
We do not sell or rent your personal information to third parties. However, we may share your data with:
</p>
<p id='privacy5'>
    <ul>
                  <li>Trusted vendors assisting with hosting, email marketing, and analytics.</li>
                  <li>In the event of a merger, acquisition, or asset sale, your data may be transferred as part of that process.</li>
                  <li>Disclosed as required by law or in response to valid legal requests.

</li>
                  <li>To identify pages being used, enabling us to analyze and improve the website.

</li>
    </ul>
</p>
<h2 id='privacy3'>4. Data Security
</h2>
<p id='privacy4'>
              We implement appropriate technical and organizational measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.

</p>

<h2 id='privacy3'>5. Your Rights</h2>
<p id='privacy4'>
Depending on your location and applicable laws, you may have the following rights concerning your data:
</p>
<p id='privacy5'>
    <ul>
                  <li>Access: Request a copy of the personal data we hold.</li>
                  <li>Rectification: Correct inaccuracies in your personal data.</li>
                  <li>Erasure: Request deletion of your data under specific circumstances.</li>
                  <li>Objection: Object to certain types of data processing.</li>
                  <li>Data Portability: Receive your data in a structured, machine-readable format.</li>
                  <li>To exercise these rights, contact us at presumedigitech@gmail.com.</li>
    </ul>
</p>
<h2 id='privacy3'>6. Cookies and Tracking Technologies</h2>
<p id='privacy4'>
We use cookies and other tracking technologies to improve your experience on our website and gather data for analytics. You can manage your cookie preferences through your browser settings.
</p>

<h2 id='privacy3'>7. Third-Party Links</h2>
<p id='privacy4'>
              Our website may contain links to external websites. These third-party websites have their own privacy policies, and we are not responsible for their content or practices. We encourage you to review the privacy policies of these sites before sharing any information.
</p>

<h2 id='privacy3'>8. Changes to This Privacy Policy</h2>
<p id='privacy4'>
              We may update this policy from time to time. Any changes will be reflected on this page with the updated effective date. We encourage you to check this page periodically to stay informed about our data protection practices.
</p>

<h2 id='privacy3'>9. Contact Us
</h2>
<p id='privacy4'>
If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:<br/>
PRESUME DIGITECH<br/> 410, Apollo Premier, Vijay Nagar, Indore, Madhya Pradesh 452011<br/> presumedigitech@gmail.com<br/> +91 8959575773
</p>











{/* Footer Section */}
<Footer />




    </div>
  );
}

export default Privacy;
