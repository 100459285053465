import "./homepage.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
const Terms = () => {
  return (
    <>
      <Header />
      <div className="terms__container">
        <br />
        <br />
        <h1>Terms & Conditions</h1>
        <br></br>
        <p>
          By accessing and using Presume Digitech services or placing an order
          with us, you confirm that you agree with and are bound by the terms
          and conditions outlined in these Terms of Use. These terms apply to
          the entire website https://presumedigitech.com and any email or other
          communication between you and Presume Digitech. Under no circumstances
          shall the Presume Digitech team be liable for any direct, indirect,
          special, incidental, or consequential damages, including but not
          limited to, loss of data or profit, arising out of the use or
          inability to use the materials or services on this site, even if the
          Presume Digitech team or an authorized representative has been advised
          of the possibility of such damages. If your use of materials from this
          site results in the need for servicing, repair, or correction of
          equipment or data, you assume any costs thereof. Presume Digitech
          reserves the right to change prices, service details, and usage
          policies at any time without prior notice.
        </p>
        <br />
        <br />
        <h2>Billing</h2>
        <br></br>
        <p>
          Presume Digitech does not charge recurring fees. All transactions are
          initiated by the user, and we do not store any billing-related data.
          Payments are processed securely through third-party payment gateways,
          such as Razorpay and PayU.
        </p>
        <br />
        <br />
        <h2>Services</h2>
        <br></br>
        <p>
          At Presume Digitech, we provide digital marketing, web development,
          and related IT services. Details of services include:
          <ul>
            <li>
              ☛ Web Development: Custom website design and development tailored
              to your business needs.
              <br />
            </li>
            <li>
              ☛ Digital Marketing: SEO, SEM, content marketing, and social media
              management.
            </li>
            <li>
              ☛ Branding Solutions: Logo design, corporate identity creation,
              and brand strategy services.
            </li>
            <li>
              ☛ Technical Support: Maintenance and troubleshooting for websites
              and online platforms.
            </li>
          </ul>
          <br />
          <br></br>
          Presume Digitech reserves the right to:
          <br />
          ☛Change the price or scope of any service at any time.<br></br>
          ☛Limit access to specific services based on availability or technical
          constraints.
          <br />
          ☛Make updates or modifications to tools and resources as necessary.
        </p>
        <br />
        <br />
        <h2>Account Suspension</h2>
        <br></br>
        <p>
          Presume Digitech reserves the right to suspend or ban user accounts
          under the following conditions:
          <br /> ☛ Sharing account credentials with unauthorized individuals.{" "}
          <br />
          ☛ Multiple logins from the same IP address or attempts to bypass
          security protocols using proxies or VPNs.
          <br />
          <br />
          Account suspension or bans will be enforced without prior notification
          in cases of violations.
        </p>
        <br/><br/>

        <h2>Disclaimer</h2>
        <br></br>
        <p>
          Presume Digitech's services are optimized for use on desktop and
          laptop devices. We do not guarantee full functionality on other
          platforms, such as mobile devices or tablets, unless explicitly
          stated.
        </p>
    <br/><br/>
        <h2>Changes to Terms of Use</h2>
        <br></br>
        <p>
          We reserve the right to update these Terms of Use at any time. Changes
          will be posted on this page with an updated effective date. We
          encourage users to review this page periodically to stay informed of
          any updates.
        </p>
      </div>

      <Footer />
    </>
  );
};

export default Terms;
